import React, { useState } from 'react'
import { Anchor, useResolution, useLocation, slugify } from '@ecommerce/shared'
import { Configure } from 'react-instantsearch-dom'
import { navigate } from 'gatsby'
import { RemoveScroll } from 'react-remove-scroll'
import { Icon } from '../Icon/Icon'
import Filter from '../Algolia/Filter'
import Loading from '../ShowLoading'
import IsFilter from '../Algolia/FilterSelected'
import ResultProducts from '../Algolia/ResultProducts'
import { FiltersHomeSearch, InfoEmptySearch, sortListAlgolia } from '../../mocks'
import secrets from '../../config/secrets'
import CustomSort from '../Algolia/CustomSort'
import { ContainerProps } from './types'
import PromotionPageBlocks from './Blocks'
import { Container, Wrapper, Title, BlockedBox, toPrefix } from './styled'
import CategoriesFilter from './CategoriesFilter'

const PromotionContainer = (props: ContainerProps) => {
  const { currentCity, refinementListSelected, onHitClick, blocks = [], categories = [] } = props
  const [isOpenFilter, setIsOpenFilter] = useState(false)

  const { isDesktop } = useResolution()
  const { textByCountry, isBolivia } = useLocation()

  const onToggleFilter = () => setIsOpenFilter((prev) => !prev)

  return (
    <Container>
      <Loading />
      {isOpenFilter && <BlockedBox />}
      {blocks && blocks.length ? <PromotionPageBlocks blocks={blocks} citySlug={currentCity.slug} /> : null}
      <Wrapper isOpenFilter={isOpenFilter}>
        <div className={toPrefix('left')}>
          <Anchor className={toPrefix('left-go_back')} onClick={() => navigate(`/${currentCity.slug}`)}>
            <Icon iconId="arrow_left" />
            <span>Volver a inicio</span>
          </Anchor>
          <RemoveScroll enabled={isOpenFilter}>
            <Filter
              onCloseFilter={onToggleFilter}
              filters={FiltersHomeSearch}
              refinementListSelected={refinementListSelected}
            />
          </RemoveScroll>
        </div>

        <div className={toPrefix('right')}>
          <Title className={toPrefix('right-title')}>
            <span className={toPrefix('right-current')}>{textByCountry('Promociones', 'Ofertas')}</span>
            {isDesktop && <CustomSort items={sortListAlgolia} defaultRefinement={secrets.ALGOLIA_INDEX_NAME} />}
            <Anchor className={toPrefix('right-filter')} onClick={onToggleFilter}>
              <IsFilter />
              <span>Filtros</span>
            </Anchor>
          </Title>
          <Configure
            hitsPerPage={24}
            filters={`${isBolivia() ? 'hasStock:true AND' : ''} slugLocation:${slugify(
              currentCity.commerceLayer.stockLocation.name,
            )} AND (hasDiscount:true OR specialDiscount:PROMOCIÓN)`}
          />
          {categories && categories.length ? (
            <CategoriesFilter attribute="mainCategoryName" categories={categories} />
          ) : null}
          <ResultProducts urlImage={InfoEmptySearch.urlImage} text={InfoEmptySearch.text} onHitClick={onHitClick} />
        </div>
      </Wrapper>
    </Container>
  )
}

export default PromotionContainer
