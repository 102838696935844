import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper/core'
import { connectRefinementList } from 'react-instantsearch-dom'
import { RefinementListProvided } from 'react-instantsearch-core'
import { breakpoints, toCssPrefix } from '@ecommerce/shared'
import CategoryBox from './CategoryBox'
import { ContainerProps } from '../types'
import useContentfulGlobal from '../../../hooks/useContentfulGlobal'

type OwnProps = {
  categories: ContainerProps['categories']
}
type Props = RefinementListProvided & OwnProps

const { cssPrefix, toPrefix } = toCssPrefix('CategoriesFilter__')

const Wrapper = styled.div`
  margin: 0 25px;
  width: 100%;

  .${cssPrefix} {
    &slide {
      width: 100px;
      margin-right: 10px;

      &:last-child {
        margin-right: 70px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    margin: 35px 0 0;
  }
`

const CategoriesFilter = ({ categories = [], refine, currentRefinement }: Props) => {
  const [swiper, setSwiper] = useState<SwiperCore>()

  const { mainAlcoholCategoryName, alcoholCategories, mainWineCategoryName, wineCategories } = useContentfulGlobal()

  const getFilters = (categoryName: string) => {
    let filters = currentRefinement?.includes(categoryName)
      ? currentRefinement.filter((filter) => filter !== categoryName)
      : [...(currentRefinement ?? []), categoryName]

    if (filters.includes(mainAlcoholCategoryName)) {
      filters.push(...alcoholCategories)
    } else if (filters.includes(mainWineCategoryName)) {
      filters.push(...wineCategories)
    } else {
      filters = filters.filter((name) => ![...alcoholCategories, ...wineCategories].includes(name))
    }

    return [...new Set(filters)]
  }

  const onFilter = ({ mainCategoryName }: typeof categories[0]) => {
    if (typeof refine === 'function') refine(getFilters(mainCategoryName))
  }

  useEffect(() => {
    if (currentRefinement.length && typeof swiper?.slideTo === 'function') {
      swiper.slideTo(categories.findIndex((cat) => cat.mainCategoryName === currentRefinement[0]))
    }
  }, [swiper])

  const isSelected = useCallback(
    (name: string) => {
      return currentRefinement.includes(name)
    },
    [currentRefinement],
  )

  return (
    <Wrapper className={cssPrefix}>
      <Swiper onSwiper={setSwiper} className={toPrefix('slider')} slidesPerView="auto">
        {categories.map((category) => (
          <SwiperSlide className={toPrefix('slide')}>
            <CategoryBox
              className={toPrefix('category-box')}
              isHighlighted={isSelected(category.mainCategoryName)}
              useGrayScale={currentRefinement.length > 0 && !isSelected(category.mainCategoryName)}
              category={category}
              onClick={() => onFilter(category)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default connectRefinementList<Props>(CategoriesFilter)
