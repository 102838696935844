import React from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { Swiper, SwiperSlide } from 'swiper/react'
import { toCssPrefix, breakpoints, useLocation, i18n } from '@ecommerce/shared'
import { Promo } from './common'
import PromoCardSkeleton from '../Skeletons/PromoCardSkeleton'
import { TagManagerEventData } from '../../graphql/contentfulTypes'
import PromoCardLarge from './PromoCardLarge'

export type PromoCardType = 'promo-small' | 'promo-large'

type Props = {
  items: (Promo & TagManagerEventData)[]
  isLoading: boolean
  title?: string
  type?: PromoCardType
  onCardClick?: (promo: Promo & TagManagerEventData, e?: React.MouseEvent | React.TouchEvent, idx?: number) => void
  className?: string
}

const PromoCardSmall = loadable(() => import('./PromoCardSmall'))

const { cssPrefix, toPrefix } = toCssPrefix('PromoListSlider__')

const Wrapper = styled.div`
  width: 100%;
  opacity: hidden;
  position: relative;

  .swiper {
    &-button {
      &-next,
      &-prev {
        display: none;
        opacity: 0.3;
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.black50};
        border-radius: 50%;
        height: 40px;
        width: 40px;
        transition: 200ms;
        &::after {
          font-weight: bold;
          font-size: 12px;
        }
        &:hover {
          opacity: 0.75;
          background-color: ${({ theme }) => theme.colors.primary};
        }
      }
    }
  }

  .${cssPrefix} {
    &title {
      margin: 0 0 22px 25px;
      color: ${({ theme }) => theme.colors.brandText.default};
    }

    &slide {
      width: fit-content;
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    .swiper {
      &-button {
        &-next,
        &-prev {
          display: flex;
        }
      }
    }

    .${cssPrefix} {
      &title {
        margin-left: 0;
      }

      &navigation {
        display: flex;
      }
    }
  }
`

export const PromoCardWrapper = styled.div<{ type: string }>`
  width: 340px;
  height: auto;
  padding: 0;
  margin-right: 25px;

  &:first-child {
    width: 320px;
    padding: 0 0 0 20px;
  }

  @media (${breakpoints.tabletPortrait.min}) and (${breakpoints.tabletPortrait.max}) {
    width: ${({ type }) => (type === 'small' ? '367px' : '380px')};

    &:first-child {
      width: ${({ type }) => (type === 'small' ? '377px' : '320px')};
    }
  }

  @media (${breakpoints.desktop.min}) {
    width: 367px;
    padding: 0;

    &:first-child {
      width: 367px;
      padding: 0;
    }
  }
`

const PromosList = ({ items, type, onCardClick, isLoading, title, className = '' }: Props) => {
  const cardType = type ?? 'promo-large'

  const { textByCountry } = useLocation()
  const currencySymbol = textByCountry(i18n.CL.currencySymbol, i18n.BO.currencySymbol)

  return (
    <Wrapper className={className}>
      {title && <h2 className={`${toPrefix('title')} ${className}-title`}>{title}</h2>}

      <Swiper navigation slidesPerView="auto" className="swiper">
        {isLoading || items.length === 0 ? (
          <>
            {Array(9)
              .fill(0)
              .map((_, i) => (
                <SwiperSlide className={toPrefix('slide')}>
                  <PromoCardWrapper type={cardType} key={i}>
                    <PromoCardSkeleton height={cardType === 'promo-small' ? 220 : undefined} />
                  </PromoCardWrapper>
                </SwiperSlide>
              ))}
          </>
        ) : (
          <>
            {items.map((promo, idx) => (
              <SwiperSlide className={toPrefix('slide')}>
                <PromoCardWrapper type={cardType} key={promo.id}>
                  {cardType === 'promo-small' ? (
                    <PromoCardSmall
                      onCardClick={(promotion, e) => (onCardClick ? onCardClick(promotion, e, idx) : null)}
                      data={promo}
                      className={`${className}-card card-small`}
                    />
                  ) : (
                    <PromoCardLarge
                      currencySymbol={currencySymbol}
                      onCardClick={(promotion, e) => (onCardClick ? onCardClick(promotion, e, idx) : null)}
                      data={promo}
                      clickable={!!onCardClick}
                      className={`${className}-card card-large`}
                    />
                  )}
                </PromoCardWrapper>
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </Wrapper>
  )
}

export default PromosList
