import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { breakpoints, useLocation, sendDataToGTM } from '@ecommerce/shared'
import Layout from '../components/Layout'
import PromotionContainer from '../components/PromotionContainer'
import withPageTransition from '../components/withPageTransition'
import { useCatalogHitClick } from '../hooks/useCatalogHitClick'
import secrets from '../config/secrets'
import { PgPageProps } from '../types/PgPages'
import { ClientSideBlock } from '../graphql/contentfulTypes'
import useQuery from '../hooks/useQuery'

const blocksQuery = `query PromotionsPage($slug: String!) {
  pgPageCollection(where: {location: {slug: $slug}, pageType: "promotions"}) {
    items {
      title
      blocksCollection {
        items {
          blockType
          sys {
            id
          }
        }
      }
    }
  }
}

`
type BlocksQueryResult = {
  pgPageCollection: {
    items: Array<{
      title: string
      blocksCollection?: {
        items?: ClientSideBlock[]
      }
    }>
  }
}

const { COUNTRY } = secrets

const WrapperContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 234px);
  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 206px);
  }

  @media (${breakpoints.desktop.min}) and (${breakpoints.desktop.max}) {
    padding: 77px 40px 0;
  }

  @media screen and (${breakpoints.desktopWide.min}) {
    min-height: calc(100vh - 257px);
    padding: 77px 120px 0 140px;
  }
`

const Promotion = (props: PgPageProps<{ promotionsId: string }>) => {
  const { location, pageContext, data } = props
  const { currentCity, productCategories } = pageContext

  const { textByCountry } = useLocation()
  const onCatalogHitClick = useCatalogHitClick({ listName: COUNTRY === 'BO' ? 'Ofertas' : 'Promociones', currentCity })

  const refinementList = location?.state?.refinementList
  const refinementListSelected = refinementList && Object.keys(refinementList).filter((item) => refinementList[item])

  useEffect(() => {
    sendDataToGTM({ event: 'promotionPageView' })
  }, [])

  const { data: queryData, loading } = useQuery<BlocksQueryResult>(blocksQuery, {
    variables: { slug: currentCity.slug },
  })

  const clientSideBlocks = queryData?.pgPageCollection?.items?.[0]?.blocksCollection?.items?.map((block) => ({
    blockType: block.blockType,
    contentful_id: block.sys.id,
  }))
  const blocks = !queryData || loading ? data?.allContentfulPgPage?.edges?.[0]?.node?.blocks : clientSideBlocks

  return (
    <Layout
      id="layout"
      title={textByCountry('Promociones', 'Ofertas')}
      currentCity={currentCity}
      pathName={`/${currentCity.slug}/promotion`}
      categories={productCategories}
    >
      <WrapperContainer>
        <PromotionContainer
          currentCity={currentCity}
          refinementListSelected={refinementListSelected}
          onHitClick={onCatalogHitClick}
          blocks={blocks ?? []}
          categories={productCategories}
        />
      </WrapperContainer>
    </Layout>
  )
}

export default withPageTransition(Promotion)

export const query = graphql`
  query PromotionsPageQuery($citySlug: String!) {
    allContentfulPgPage(filter: { location: { slug: { eq: $citySlug } }, pageType: { eq: "promotions" } }) {
      edges {
        node {
          title
          blocks {
            contentful_id
            blockType
          }
        }
      }
    }
  }
`
