import { breakpoints, toCssPrefix } from '@ecommerce/shared'
import React from 'react'
import styled from 'styled-components'
import { BlockType } from '../../graphql/contentfulTypes'
import Promotions from '../../templates/home/Promotions'
import { ContainerProps } from './types'

type Props = {
  blocks: ContainerProps['blocks']
  citySlug: string
}

const { cssPrefix, toPrefix } = toCssPrefix('PromotionPageBlocks__')

const Wrapper = styled.div`
  margin-top: 35px;

  .${cssPrefix} {
    &block {
      margin-bottom: 25px;
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    .${cssPrefix} {
      &block {
        margin-bottom: 40px;
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    margin: 0 0 35px;
  }
`

const PromotionPageBlocks = ({ blocks, citySlug }: Props) => {
  return (
    <Wrapper className={cssPrefix}>
      {blocks?.map((block) => {
        const id = block.contentful_id

        switch (block.blockType) {
          case BlockType.PROMO_LARGE:
          case BlockType.PROMO_SMALL:
            return (
              <div className={toPrefix('block')} key={id}>
                <Promotions listId={id} citySlug={citySlug} />
              </div>
            )

          default:
            return null
        }
      })}
    </Wrapper>
  )
}

export default PromotionPageBlocks
