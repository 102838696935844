import { ClientSideBlockQueryResult, TagManagerEventData } from './contentfulTypes'

export interface QueryPromo extends TagManagerEventData {
  promoTitle: string
  promoSubtitle: string
  promoDescription: string
  promoSlug: string
  actionSlugLong: string
  titleType: string
  image: {
    url: string
  }
  sys: {
    id: string
  }
}

export type QueryResult = ClientSideBlockQueryResult<QueryPromo>

export default `
  query PromoListQuery($listId: String!) {
    orList(id: $listId) {
      title
      blockType
      paddingTop
      paddingBottom
      itemsCollection {
        items {
          ... on MlCardPromo {
            promoTitle
            promoSubtitle
            promoDescription
            actionSlugLong
            titleType
            googleTagManagerEventType
            googleTagManagerEventValue
            image {
              url
            }
            sys {
              id
            }
          }
        }
      }
    }
  }
`
