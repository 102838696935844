import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { sendPromoViewToGTM, sendPromoClickToGTM } from '@ecommerce/shared'
import PromoList, { PromoCardType } from '../../components/PromoList'
import promoListQuery, { QueryPromo, QueryResult } from '../../graphql/promoListQuery'
import { BlockPadding, GoogleTagManagerEventType } from '../../graphql/contentfulTypes'
import useQuery from '../../hooks/useQuery'
import { SimpleBlockWrapper } from '../../utils/blocks'

interface Props {
  listId: string
  citySlug?: string
  className?: string
  themeId?: string
  showRibbon?: boolean
  itsHome?: boolean
}

type StateData = {
  title?: string
  type: PromoCardType
  items: QueryPromo[]
} & BlockPadding

const Promotions = ({ listId, citySlug, className = '', themeId, showRibbon = false }: Props) => {
  const [promosData, setPromosData] = useState<StateData>({
    type: 'promo-small',
    items: [],
  })
  const [isLoading, setLoading] = useState(true)
  const [renderLists, setRenderLists] = useState(true)

  const { data, loading, error } = useQuery<QueryResult>(promoListQuery, { variables: { listId } })

  useEffect(() => {
    if (data && !loading) {
      const block = data?.orList

      if (!block) return setRenderLists(false)

      setPromosData({
        type: (block?.blockType as PromoCardType) ?? 'promo-small',
        items: block?.itemsCollection?.items ?? [],
        title: block?.title,
        paddingTop: block.paddingTop,
        paddingBottom: block.paddingBottom,
      })

      const promotionItems =
        block?.itemsCollection?.items.filter(
          (item) =>
            item.googleTagManagerEventType === GoogleTagManagerEventType.PROMOTION && !!item.googleTagManagerEventValue,
        ) ?? []

      if (promotionItems.length)
        sendPromoViewToGTM(
          promotionItems.map((item) => ({
            id: item.googleTagManagerEventValue ?? '',
            name: item.googleTagManagerEventValue ?? '',
            creative: item.promoTitle,
            position: `${
              (block.itemsCollection.items.findIndex((bannerItem) => bannerItem.sys.id === item.sys.id) ?? 0) + 1
            }`,
          })),
        )
      setLoading(false)
    }
  }, [data, loading])

  if (!renderLists || error !== undefined) return null

  return (
    <SimpleBlockWrapper {...promosData}>
      <PromoList
        className={className}
        items={
          promosData?.items.map((promo) => ({
            id: promo.sys.id,
            title: promo?.promoTitle || '',
            titleType: promo.titleType ?? '',
            subtitle: promo?.promoSubtitle || '',
            image: promo?.image?.url || '',
            description: promo?.promoDescription || '',
            slug: promo?.actionSlugLong || promo?.promoSlug || '',
            googleTagManagerEventType: promo.googleTagManagerEventType,
            googleTagManagerEventValue: promo.googleTagManagerEventValue,
          })) ?? []
        }
        type={promosData?.type ?? 'promo-large'}
        title={promosData?.title}
        onCardClick={(promo, _, idx) => {
          if (
            promo.googleTagManagerEventType === GoogleTagManagerEventType.PROMOTION &&
            !!promo.googleTagManagerEventValue
          )
            sendPromoClickToGTM({
              id: promo.googleTagManagerEventValue,
              name: promo.googleTagManagerEventValue,
              creative: promo.title,
              position: `${idx ? idx + 1 : 0}`,
            })

          if (promo.slug)
            navigate(promo.slug?.startsWith('/') ? promo.slug : `/${citySlug}/${promo.slug}`, {
              state: { themeId },
            })
        }}
        isLoading={isLoading}
      />
    </SimpleBlockWrapper>
  )
}

export default Promotions
